import React from 'react'

const MouthIH = () => (
  <path
    d="M117 161C134.673 161 149 153.837 149 145H85C85 153.837 99.3269 161 117 161Z"
    fill="#1B2D3C"
  />
)

export default MouthIH
